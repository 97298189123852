<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">
    
    <!--begin::Notifications-->
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      <!--begin::Menu-->
      <!--end::Menu-->
    </div>
    <!--end::Notifications-->
    <div
      class="d-flex align-items-center ms-1 ms-lg-3"
    >

    </div>

    <div
      class="d-flex align-items-center ms-1 ms-lg-3"
    >
      <b>{{ state.societe }}</b>
    </div>

    <!--begin::User-->
    <div
      class="d-flex align-items-center ms-1 ms-lg-3 gap-2"
      id="kt_header_user_menu_toggle"
    >

    <!--begin::Menu-->
    <div class="symbol symbol-30px symbol-md-40px">
        <span class="cursor-pointer symbol-label svg-icon svg-icon-primary svg-icon-1 me-2" @click="openPanier()">
          <inline-svg src="media/icons/duotune/ecommerce/ecm002.svg"/>
        </span>
        <el-drawer v-model="state.displayPanier" size="900px" modal-class="modal-transp" :title="$t('Votre demande de réservation')">
          <Panier :key="state.panierKey"/>
        </el-drawer>
      </div>
      <!--end::Menu-->
      
      <!--begin::Menu-->
      <div
        class="cursor-pointer symbol symbol-30px symbol-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <div class="symbol-label fs-2 fw-bold text-primary">
          {{ state.initialContact }}
        </div>
      </div>
      <KTUserMenu></KTUserMenu>
      <!--end::Menu-->
    </div>
    <!--end::User -->

  </div>
  <!--end::Toolbar wrapper-->
</template>

<script lang="ts">
import { defineComponent, watch, reactive, onMounted } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";
import mAxiosApi from "@/api";
import Panier from "@/views/still/deploy/panier.vue";


export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
    Panier
  },
  emits: ["reloadRouter"],

  setup(props, { emit }) {
    const store = useStore();

    const reloadRouter = () => {
      emit("reloadRouter", 1);
    }

    const state = reactive({
      initialContact: "",
      societe: "test jle",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      contact: {} as any,
      myList: [] as any,
      displayPanier: false,
      panierKey: 0,
    });

    function varState() {
      if (!store.getters.isUserAuthenticated) return false;
      state.contact = store.getters.currentUser;

      state.societe = store.getters.currentUser.societeCodeArk ? store.getters.currentUser.societeCodeArk : "";

      state.initialContact =
        (state.contact.uti_c_prenom ? state.contact.uti_c_prenom.charAt(0) : "") +
        (state.contact.uti_c_nom ? state.contact.uti_c_nom.charAt(0) : "");
    }

    onMounted(async () => {

      let myListLiv = {} as any;
      myListLiv = await getAxios("/getMyRole");
      state.myList = myListLiv;

    });

    watch(
      () => store.getters.currentUser,
      function () {
        varState();
      }
    );

    if (!store.getters.isUserAuthenticated) {
      // router.push({ name: "sign-in" });
    } else {
      varState();
    }

    const openPanier = () => {
      state.displayPanier = true;
      state.panierKey = state.panierKey + 1
    }

    return { 
      state, 
      reloadRouter,
      openPanier,  
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
