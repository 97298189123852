
import { defineComponent, watch, reactive, onMounted } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";
import mAxiosApi from "@/api";
import Panier from "@/views/still/deploy/panier.vue";


export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
    Panier
  },
  emits: ["reloadRouter"],

  setup(props, { emit }) {
    const store = useStore();

    const reloadRouter = () => {
      emit("reloadRouter", 1);
    }

    const state = reactive({
      initialContact: "",
      societe: "test jle",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      contact: {} as any,
      myList: [] as any,
      displayPanier: false,
      panierKey: 0,
    });

    function varState() {
      if (!store.getters.isUserAuthenticated) return false;
      state.contact = store.getters.currentUser;

      state.societe = store.getters.currentUser.societeCodeArk ? store.getters.currentUser.societeCodeArk : "";

      state.initialContact =
        (state.contact.uti_c_prenom ? state.contact.uti_c_prenom.charAt(0) : "") +
        (state.contact.uti_c_nom ? state.contact.uti_c_nom.charAt(0) : "");
    }

    onMounted(async () => {

      let myListLiv = {} as any;
      myListLiv = await getAxios("/getMyRole");
      state.myList = myListLiv;

    });

    watch(
      () => store.getters.currentUser,
      function () {
        varState();
      }
    );

    if (!store.getters.isUserAuthenticated) {
      // router.push({ name: "sign-in" });
    } else {
      varState();
    }

    const openPanier = () => {
      state.displayPanier = true;
      state.panierKey = state.panierKey + 1
    }

    return { 
      state, 
      reloadRouter,
      openPanier,  
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
